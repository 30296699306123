import React, { useContext } from 'react';
import { Table, Input } from 'antd';
import { Link } from 'react-router-dom'; import {
  EyeOutlined,
  SearchOutlined,
  EyeFilled,
} from '@ant-design/icons';
import { UserContext } from '../../App';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const InvoicesTable = ({ data, title, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + `${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 210, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small' 
            style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      key: 'BrojDOk',
      title: 'Broj računa',
      dataIndex: 'BrojDOk',
      ...getColumnSearchProps('BrojDOk')
    },
    {
      key: 'PrimalacNaziv',
      title: 'Kupac',
      dataIndex: 'PrimalacNaziv',
      ...getColumnSearchProps('PrimalacNaziv')
    },
    {
      key: ['Kupac', '0', 'email'],
      title: 'Email',
      dataIndex: ['Kupac', '0', 'email'],
      ...getColumnSearchProps(['Kupac', '0', 'email'])
    },
    {
      key: ['Kupac', '0', 'tel'],
      title: 'Broj telefona',
      dataIndex: ['Kupac', '0', 'tel'],
      ...getColumnSearchProps(['Kupac', '0', 'tel'])
    },
    {
      key: 'kreirao',
      title: 'Kreirao',
      dataIndex: 'kreirao',
      ...getColumnSearchProps('kreirao')
    },
    {
      key: 'Datum',
      title: 'Datum kreiranja',
      render: (record) => (
        <span>{new Date(record.Datum).toLocaleDateString('sr-Latn-RS', dateOptions)}</span>
      )
    },
  ];


  columns.push({
    title: 'action',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={viewPath + record._id}>
            <EyeOutlined
              className='icon-unlock'
              title={`View ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
            <EyeFilled
              className='icon-lock'
              title={`View ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>
      </div>
    ),
  });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default InvoicesTable;
