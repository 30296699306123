import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ProFormInvoiceForm from '../../components/forms/ProFormInvoiceForm';

const EditproFormInvoices = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [proFormInvoices, fetchProFormInvoices] = useAxios('', {}, currentuser.data.token, 'get');
  const { proFormId } = props.match.params;
  useEffect(() => {
    if (proFormId) {
      fetchProFormInvoices(`${SERVER_URL}/proFormInvoices/${proFormId}`, {});
    }
  }, [fetchProFormInvoices, proFormId]);
  const onSubmit = async (formData) => {
    console.log(formData);

    const method = 'put';
    const route = `${SERVER_URL}/proFormInvoices/${proFormId}`;

    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Profaktura je izmenjena.`,
        placement: 'bottomRight',
      });
      // history.push('/admin/orders');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const createInvoice = async (orderData) => {
    // const method = 'post';
    // const route = `${SERVER_URL}/invoices`;
    // try {
    //   await Axios[method](route, { offerId: offerId, email: offerId.data.user.email, dateOfPayment: offerId.data.createdAt, order: orderData }, {
    //     withCredentials: false,
    //     headers: { Authorization: `Bearer ${currentuser.data.token}` },
    //   });
    //   notification.success({
    //     message: `Račun je kreiran.`,
    //     placement: 'bottomRight',
    //   });
    //   // history.push('/admin/orders');
    // } catch (error) {
    //   const msg = error.response ? error.response.data.message : error.message ? error.message : error;
    //   console.log(error);
    //   notification.error({
    //     message: msg,
    //     placement: 'bottomRight',
    //   });
    // }
  };

  const isDataFetched =
    !proFormInvoices.isLoading &&
    proFormInvoices &&
    proFormInvoices.data &&
    proFormInvoices.data.length > 0 &&
    currentuser.language;
  // if (isDataFetched) {
  //   order.data.user.userName = order.data.user.firstName + ' ' + order.data.user.lastName;
  // }

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/company/proform-invoices'>
          <button className='b2b-primary'>Sve profakture</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {proFormId && !proFormInvoices.isError && proFormInvoices.data.length && isDataFetched && (
          <ProFormInvoiceForm
            data={proFormInvoices.data[0]}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
            allowEdit={!currentuser?.data?.role?.includes('company')}
            createInvoice={createInvoice}
          />
        )}
      </div>
    </div>
  );
};

export default EditproFormInvoices;
