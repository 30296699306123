import React, { useContext } from 'react';
import { Table, Popconfirm, Input, Button, Select } from 'antd';
import { DeleteOutlined, SearchOutlined, DeleteFilled, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const OrderedProductsTable = ({
  data,
  deleteFromOrderHandler,
  columnKeys,
  title,
  increaseAmount,
  decreaseAmount,
  amount,
  changeOrderedPrice,
  changePDV,
  changeOrderedRebate,
  changeComment,
}) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small'
            // style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const columns = [
    {
      title: 'Šifra',
      key: 'code',
      dataIndex: 'code',
      width: '10%',
    },
    {
      title: 'Naziv',
      key: 'title',
      dataIndex: 'title',
      width: '35%',
    },
  ];
  columns.push(
    {
      title: 'Cena',
      render: (text, record) => (
        <>
          {!user.data.role.includes('superAdmin') ? (
            <span> {record.wholesalePrice !== 'NaN' ? `${record.wholesalePrice} €` : ''}</span>
          ) : (
            <div
              style={{
                margin: '0 auto',
                width: '60px',
                outline: '0',
                borderBottom: '1px solid black',
                display: 'flex',
              }}
            >
              <Input
                // disabled={status !== 'On hold'}
                style={{ padding: '0' }}
                bordered={false}
                placeholder=''
                onChange={(e) => changeOrderedPrice(e.currentTarget.value, record.code)}
                defaultValue={record.wholesalePrice && record.wholesalePrice}
              />
              €
            </div>
          )}
        </>
      ),
    },
    {
      title: 'quantity',
      render: (text, record, index) => (
        <div style={{ display: 'inline-flex', justifyContent: 'left', alignItems: 'center', fontSize: '15px' }}>
          <Button
            disabled={record.amount === record.onStock}
            onClick={() => increaseAmount(record.code)}
            icon={<PlusOutlined />}
            style={{ border: '0', width: '15px', marginRight: '5px' }}
          />
          <span>{record.amount}</span>
          <Button
            onClick={() => decreaseAmount(record.code)}
            icon={<MinusOutlined />}
            style={{ border: '0', width: '15px', marginLeft: '5px' }}
          />
        </div>
      ),
    },
  );
  columns.push({
    title: 'Popust',
    render: (text, record, index) => (
      <>
        {!user.data.role.includes('superAdmin') ? (
          <span> {record.rebate} %</span>
        ) : (
          <div
            style={{ margin: '0 auto', width: '60px', outline: '0', borderBottom: '1px solid black', display: 'flex' }}
          >
            <Input
              // disabled={status !== 'On hold'}
              style={{ padding: '0' }}
              bordered={false}
              placeholder='%'
              onChange={(e) => changeOrderedRebate(e.currentTarget.value, record.code)}
              defaultValue={record.rebate && record.rebate}
            />
            %
          </div>
        )}
      </>
    ),
  });
  columns.push({
    title: 'total',
    render: (text, record, index) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {record.wholesalePrice !== 'NaN'
          ? `${(
              record.amount * record.wholesalePrice -
              (record.amount * record.wholesalePrice * record.rebate) / 100
            ).toFixed(2)}`
          : ''}{' '}
        €
      </div>
    ),
  });
  columns.push({
    title: 'Stopa PDV',
    width: '15%',
    render: (text, record, index) => (
      <div style={{ margin: '0 auto', width: '60px', outline: '0', borderBottom: '1px solid black', display: 'flex' }}>
        <Select
          dropdownStyle={{ minWidth: '20%' }}
          style={{ padding: '0' }}
          bordered={false}
          onChange={(e) => changePDV(e, record.code)}
        >
          <Option value={null}>0%</Option>
          <Option value='PDVPS'>10%</Option>
          <Option value='PDVOS'>20%</Option>
        </Select>
      </div>
    ),
  });
  columns.push({
    title: 'action',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Ovo će obrisati ${record.code} sa narudžbe`}
            onConfirm={() => {
              deleteFromOrderHandler(record.code);
            }}
            okText='U redu'
            cancelText='Poništi'
            cancelButtonProps={{ className: 'b2b-secondary' }}
            okButtonProps={{ className: 'b2b-primary' }}
          >
            <DeleteOutlined className='icon-unlock' title={`Obriši ${record.code}`} />
            <DeleteFilled className='icon-lock' title={`Obriši ${record.code}`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });
  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }
  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='code'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        expandable={{
          showExpandColumn: false,
          defaultExpandAllRows: true,
          expandedRowKeys: data.map((o) => o.code),
          expandedRowRender: (record) => (
            <div style={{ borderBottom: '1px solid #d9d9d9', paddingBottom: '10px', display: 'flex' }}>
              <Input
                bordered={true}
                placeholder='Komentar'
                onChange={(e) => changeComment(record, e?.currentTarget.value)}
                defaultValue={record.note && record.note}
              />
            </div>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
      />
    </div>
  );
};

export default OrderedProductsTable;
