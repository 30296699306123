import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';

const Routes = () => {
  const user = useContext(UserContext);

  return (
    <Switch>
      <ProtectedRoute
        user={user.data}
        exact
        path='/company'
        component={Home}
        allowed={['superAdmin', 'companyAdmin', 'company']}
      />
      {/* routes from routeConfig file */}
      {ROUTES.map((route) =>
        route.children.map((item, i) => (
          <ProtectedRoute
            exact
            user={user.data}
            key={`R_${i}`}
            path={item.path}
            component={item.component}
            allowed={item.allowed}
          />
        )),
      )}
      <Route
        exact
        path='/login'
        render={() => (user && user.isLoggedIn ? <Redirect to='/company/data' /> : <Login />)}
      />
      <Route render={() => <Redirect to='/company/data' />} />
    </Switch>
  );
};

export default Routes;
