import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Select } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
// import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
// import i18n from '../../i18n';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav } = props;
  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    localStorage.removeItem('orderedProducts');
    localStorage.removeItem('amounts');
    localStorage.removeItem('totalPrice');
    window.location.href = '/login';
  };

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6, overflowX: 'hidden' }}
    >
      <Link to='/company/data' className='logo-desktop'>
        <div className='logo'>
          <img src='/images/ibs_logo.svg' style={{ maxHeight: '49px', width: '70%' }} alt='IBS logo' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {currentuser.data &&
          currentuser.data.role &&
          ROUTES.filter((route) => route.showInMenu.some((item) => currentuser.data.role.includes(item))).map(
            (route) => (
              <SubMenu
                className='submenu'
                key={route.label.toLowerCase()}
                title={isCollapsed ? <route.icon /> : <span>{route.label}</span>}
              >
                {route.children
                  .filter((item) => item.showInMenu)
                  .map((item) => (
                    <Menu.Item key={item.path}>
                      <Link to={item.path}>{item.label}</Link>
                    </Menu.Item>
                  ))}
              </SubMenu>
            ),
          )}
      </Menu>
      <div className='sider-profile-controls'>
        <a
          href={`/company/view-user/${currentuser && currentuser?.data?.id}`}
          className='sider-profile-controls-profile'
        >
          <UserOutlined />
        </a>

        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
        {/* <Select
          getPopupContainer={trigger => trigger.parentNode}
          dropdownAlign={{ offset: [0, -105] }}
          className='sider-profile-controls sider-profile-controls-language'
          style={{ position: 'absolute', right: '2rem', top: '50%', transform: 'translateY(-50%)' }}  //ovo obrisati ako se ponovo omoguci
          value={currentuser.language && currentuser.language.selected.name}
          onChange={(lang) => {
            i18n.changeLanguage(lang);
            currentuser.setLanguage({
              ...currentuser.language,
              selected: currentuser.language.list.find((l) => l.name === lang),
            })}
          }
        >
          {currentuser.language &&
            currentuser.language.list.map((lang) => (
              <Select.Option value={lang.name} key={lang.name}>
                {lang.name}
              </Select.Option>
            ))}
        </Select> */}
      </div>
    </Sider>
  );
};
export default NavMenu;
