import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'user',
  'userId'
];

const Invoices = () => {
  const currentuser = useContext(UserContext);
  const [invoices, fetchInvoices] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchInvoices(`${SERVER_URL}/invoices`, []);
  }, [fetchInvoices]);

  let columnKeys;
  if (invoices.data && invoices.data.items && invoices.data.items.length > 0) {
    const keys = Object.keys(invoices.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(1, 0, 'userEmail');
  }

  let tableData = [];
  if (invoices.data && invoices.data.items && invoices.data.items.length > 0) {
    tableData = invoices.data.items.map((item) => {
      if (item.userId) {
        item.userEmail = item.userId.email;
      }
      return item;
    }).filter((item) => item._id === currentuser.id);
  }

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        {invoices.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!invoices.isLoading && invoices.data && invoices.data.items && invoices.data.items.length > 0 && (
          <Table data={tableData} columnKeys={columnKeys} title='Računi'
            viewPath='/company/view-invoice/' />
        )}
        {!invoices.isLoading && invoices.data && invoices.data.items && invoices.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Invoices;