import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/OffersTable';
import { SERVER_URL } from '../../config';

const Offers = () => {
  const currentuser = useContext(UserContext);
  const [offers, fetchOffers] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    let link;
    const userId = currentuser.data.id;
    link = `${SERVER_URL}/offers-by-user/${userId}`;
    fetchOffers(link, []);
  }, [currentuser.data.id, currentuser.data.role, currentuser.data.userType, fetchOffers]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/company');
      history.push('/company/orders');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let tableData = [];
  if (offers.data && offers.data.items && offers.data.items.length > 0) {
    tableData = offers.data.items.map((item) => {
      return item;
    });
  }

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        {offers.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!offers.isLoading && offers.data && offers.data.items && offers.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteOrderHandler}
            title='Offers'
            viewPath='/company/view-offer/'
          />
        )}
        {!offers.isLoading && offers.data && offers.data.items && offers.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Offers;
