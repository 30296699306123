import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined, QuestionOutlined } from '@ant-design/icons';
import { notification, Button, Row, Col, Select, Divider, Input, Radio, Space } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import OrderedProductsTable from '../../components/tables/OrderedProductsTable';
import { SERVER_URL, IBS_TEST_URL } from '../../config';
import TextArea from 'antd/es/input/TextArea';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'featuredProduct',
  'inactiveProduct',
  'superPrice',
  'newProduct',
  'discountWholesale',
  'discountRetail',
  'supplier',
  'createdAt',
  'updatedAt',
  'retailPrice',
  'discountRetailPrice',
  'discountWholesalePrice',
  'artikalID',
  'barkod',
  'nacinzaokrtanspak',
  'zaokrtranspak',
  'zemljaporekla',
  'zemljauvoza',
  'nacinzaokrtranspak',
  'uvoznik',
  'kataloskibrojevi',
  'IDgrupe',
  'nazivgrupe',
  'IDOsnovneGrupe',
  'nazivosnovnegrupe',
  'imaraster',
  'naakciji',
  'imarecepturu',
  'pokontrolnombroju',
  'poserijskombroju',
  'kreirano',
  'nekoristise',
  'nazivukasi',
  'stanje',
  'sifra',
  'jdm',
  'state',
  'cena',
  'kategorija',
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [loggedUser, fetchLoggedUser] = useAxios('', [], currentuser.data.token, 'get');
  const [orders, fetchOrders] = useAxios('', [], currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [companyName, setCompanyName] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [comment, setComment] = useState(
    localStorage.getItem('orderedProductComment') ? JSON.parse(localStorage.getItem('orderedProductComment')) : '',
  );
  const [reload, triggerReload] = useState(false);
  const [rerender, triggerRerender] = useState(false);
  const [value, setValue] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const history = useHistory();
  let [amount, setAmount] = useState(
    localStorage.getItem('amounts') ? JSON.parse(localStorage.getItem('amounts')) : [],
  );
  let total = localStorage.getItem('totalPrice') ? parseFloat(localStorage.getItem('totalPrice')) : 0;
  let orderedProducts = localStorage.getItem('orderedProducts')
    ? JSON.parse(localStorage.getItem('orderedProducts'))
    : [];
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    fetchData(`${SERVER_URL}/data-admin`, []);
    if (currentuser.data.role.includes('superAdmin')) {
      fetchCompanies(`${SERVER_URL}/companies`, []);
    }
    fetchLoggedUser(`${SERVER_URL}/users-company/${currentuser.data.id}`, []);
    fetchOrders(`${SERVER_URL}/orders`, []);
  }, [fetchData, fetchOrders, fetchLoggedUser, currentuser.data.id]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je izbrisana.',
        placement: 'bottomRight',
      });
      history.push('/company');
      history.push('/company/data');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys = [];
  let orderTableColumnKeys;

  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    orderTableColumnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('naziv')) {
      columnKeys.push('naziv');
    }
    if (!columnKeys.includes('sifra')) {
      columnKeys.push('sifra');
    }
    let name, code;
    for (const columnKey of columnKeys) {
      if (columnKey === 'naziv') {
        name = {
          originalName: columnKey,
          alternativeName: 'Naziv',
        };
      } else if (columnKey === 'sifra') {
        code = {
          originalName: columnKey,
          alternativeName: 'Šifra',
        };
      }
    }
    newColumnKeys.push(code, name);
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.state =
        item?.stanje[0]?.stanje > 0 ? (
          <span>
            <CheckCircleFilled /> Na stanju
          </span>
        ) : (
          <span>Na upit</span>
        );
      return item;
    });

    tableData = data.data.items.map((item) => {
      tableData = data.data.items.map((item) => {
        item.reserved = item.stanje.reduce((accumulator, currentValue) => accumulator + currentValue.reserved, 0);
        if (isNaN(item.reserved)) {
          item.reserved = 0;
        }
        item.loan = item.stanje.reduce((accumulator, currentValue) => accumulator + currentValue.loan, 0);
        if (isNaN(item.loan)) {
          item.loan = 0;
        }
      });

      return item;
    });
  }
  const addInCart = (propcina, rebate) => {
    let rebate1 = (rebate && rebate.discount) || 0;
    const selectedProduct = tableData.find((item) => item.sifra === propcina);
    orderedProducts.push({
      title: selectedProduct.naziv,
      tarifa: selectedProduct.tarifa,
      wholesalePrice: selectedProduct.cena.length ? parseFloat(selectedProduct.cena[0].Cena).toFixed(2) : 0,
      code: propcina,
      amount: amount.find((item) => item.code === propcina).quantity,
      category: selectedProduct.kategorija[0].naziv,
      rebate: rebate1,
    });
    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    // total += (+selectedProduct.wholesalePrice - (+selectedProduct.wholesalePrice * rebate1 / 100));
    // localStorage.setItem('totalPrice', total);
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    triggerReload((prev) => !prev);
    notification.info({
      message: `Proizvod je dodat u korpu.`,
      placement: 'bottomRight',
      duration: 0.5,
    });
  };

  const changeComment = (product, comment) => {
    let objIndex = orderedProducts.findIndex((obj) => obj?.code == product?.code);

    orderedProducts[objIndex].note = comment;

    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    setTimeout(() => {
      triggerReload((prev) => !prev);
    }, 150);
  };

  const increaseAmount = (propcina, rebate) => {
    let amountArray = amount;
    if (amountArray.length && amountArray.find((itemcina) => itemcina.code === propcina)) {
      amountArray.forEach((item, index) => {
        if (item.code === propcina) {
          amountArray[index] = { ...item, quantity: item.quantity + 1 };
        }
      });
      orderedProducts.forEach((orderedProduct, index) => {
        if (orderedProduct.code === propcina) {
          orderedProducts[index] = { ...orderedProduct, amount: orderedProduct.amount + 1 };
          // total += (+orderedProducts[index].wholesalePrice - (+orderedProducts[index].wholesalePrice * +orderedProducts[index].rebate / 100));
          // localStorage.setItem('totalPrice', total.toFixed(2));

          localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
        }
      });
      const totalPriceTemp = orderedProducts.reduce(
        (accumulator, current) =>
          accumulator +
          (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
        0,
      );
      localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
      setAmount(amountArray);
      localStorage.setItem('amounts', JSON.stringify(amountArray));
      triggerReload((prev) => !prev);
    } else {
      amountArray.push({ code: propcina, quantity: 1 });
      localStorage.setItem('amounts', JSON.stringify(amountArray));
      setAmount(amountArray);
      addInCart(propcina, rebate);
    }
  };

  const changeAmount = (prop, e) => {
    let amountArray = amount;
    if (amountArray.length && amountArray.find((itemcina) => itemcina.code === prop)) {
      amountArray.forEach((item, index) => {
        if (item.code === prop) {
          amountArray[index] = { ...item, quantity: e };
        }
      });
      orderedProducts.forEach((orderedProduct, index) => {
        if (orderedProduct.code === prop) {
          orderedProducts[index] = { ...orderedProduct, amount: e };
          // total += (+orderedProducts[index].wholesalePrice - (+orderedProducts[index].wholesalePrice * +orderedProducts[index].rebate / 100));
          // localStorage.setItem('totalPrice', total.toFixed(2));

          localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
        }
      });
      const totalPriceTemp = orderedProducts.reduce(
        (accumulator, current) =>
          accumulator +
          (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
        0,
      );
      localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
      setAmount(amountArray);
      localStorage.setItem('amounts', JSON.stringify(amountArray));
      triggerReload((prev) => !prev);
    } else {
      amountArray.push({ code: prop, quantity: e });
      localStorage.setItem('amounts', JSON.stringify(amountArray));
      setAmount(amountArray);
      addInCart(prop, e);
    }
  };

  const decreaseAmount = (propcina) => {
    let amountArray = amount;
    amountArray.forEach((item, index) => {
      if (item.code === propcina && item.quantity !== 0) {
        amountArray[index] = { ...item, quantity: item.quantity - 1 };
        localStorage.setItem('amounts', JSON.stringify(amountArray));
        setAmount(amountArray);
      }
    });
    amountArray = amountArray.filter((amountZero) => amountZero.quantity !== 0);
    localStorage.setItem('amounts', JSON.stringify(amountArray));
    setAmount(amountArray);
    orderedProducts.forEach((item, index) => {
      if (item.code === propcina && item.amount !== 0) {
        orderedProducts[index] = { ...item, amount: item.amount - 1 };
        localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
        // total -= (orderedProducts[index].wholesalePrice - (orderedProducts[index].wholesalePrice * orderedProducts[index].rebate / 100));
        // localStorage.setItem('totalPrice', total.toFixed(2));
      }
    });
    orderedProducts = orderedProducts.filter((orderedProduct) => orderedProduct.amount !== 0);
    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    triggerReload((prev) => !prev);
  };

  const increaseAmountSecond = (propcina) => {
    let amountArray = amount;
    amountArray.forEach((item, index) => {
      if (item.code === propcina) {
        amountArray[index] = { ...item, quantity: item.quantity + 1 };
      }
    });
    orderedProducts.forEach((orderedProduct, index) => {
      if (orderedProduct.code === propcina) {
        orderedProducts[index] = { ...orderedProduct, amount: orderedProduct.amount + 1 };
        // total += (+orderedProducts[index].wholesalePrice - (+orderedProducts[index].wholesalePrice * +orderedProducts[index].rebate / 100));
        // localStorage.setItem('totalPrice', total.toFixed(2));
        localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
      }
    });
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    setAmount(amountArray);
    localStorage.setItem('amounts', JSON.stringify(amountArray));
    triggerReload((prev) => !prev);
  };

  const decreaseAmountSecond = (propcina) => {
    let amountArray = amount;
    amountArray.forEach((item, index) => {
      if (item.code === propcina && item.quantity !== 0) {
        amountArray[index] = { ...item, quantity: item.quantity - 1 };
        localStorage.setItem('amounts', JSON.stringify(amountArray));
        setAmount(amountArray);
      }
    });
    amountArray = amountArray.filter((amountZero) => amountZero.quantity !== 0);
    localStorage.setItem('amounts', JSON.stringify(amountArray));
    setAmount(amountArray);
    orderedProducts.forEach((item, index) => {
      if (item.code === propcina && item.amount !== 0) {
        orderedProducts[index] = { ...item, amount: item.amount - 1 };
        localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
        // total -= (orderedProducts[index].wholesalePrice - (orderedProducts[index].wholesalePrice * orderedProducts[index].rebate / 100));
        // localStorage.setItem('totalPrice', total.toFixed(2));
      }
    });
    orderedProducts = orderedProducts.filter((orderedProduct) => orderedProduct.amount !== 0);
    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    triggerReload((prev) => !prev);
  };

  const deleteFromOrderHandler = (propcina) => {
    const selectedProduct = orderedProducts.find((product) => product.code === propcina);
    orderedProducts = orderedProducts.filter((orderedProduct) => orderedProduct.code !== propcina);
    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    let amountArray = amount.filter((item) => item.code !== propcina);
    localStorage.setItem('amounts', JSON.stringify(amountArray));
    setAmount(amountArray);
    // total -= (+selectedProduct.wholesalePrice - (+selectedProduct.wholesalePrice * selectedProduct.rebate / 100)) * selectedProduct.amount;
    // localStorage.setItem('totalPrice', total);
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    triggerReload((prev) => !prev);
  };

  if (loggedUser.data.length !== 0 && !companyName && !phone && !address && !email) {
    if (loggedUser.data.role.includes('companyAdmin')) {
      setCompanyName(loggedUser.data?.companyAdminInfo?.companyName);
      setPhone(loggedUser.data.phone[0]);
      setAddress(
        `${loggedUser.data?.companyAdminInfo?.address}, ${loggedUser.data.companyAdminInfo?.zipCode} ${loggedUser.data.companyAdminInfo?.city}`,
      );
      setEmail(loggedUser.data.email);
    }
  }

  const changeOrderedPrice = (event, code) => {
    let oldOrderedProducts = orderedProducts.map((item) => {
      if (item.code === code) {
        item.wholesalePrice = event;
      }
      return item;
    });
    orderedProducts = oldOrderedProducts;
    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    setTimeout(() => {
      triggerRerender((prev) => !prev);
    }, 30);
  };

  const changeOrderedRebate = (event, code) => {
    let oldOrderedProducts = orderedProducts.map((item) => {
      if (item.code === code) {
        item.rebate = event;
      }
      return item;
    });
    orderedProducts = oldOrderedProducts;
    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    setTimeout(() => {
      triggerRerender((prev) => !prev);
    }, 30);
  };

  const changePDV = (event, code) => {
    let oldOrderedProducts = orderedProducts.map((item) => {
      if (item.code === code) {
        item.tarifa = event;
      }
      return item;
    });
    orderedProducts = oldOrderedProducts;
    localStorage.setItem('orderedProducts', JSON.stringify(orderedProducts));
    const totalPriceTemp = orderedProducts.reduce(
      (accumulator, current) =>
        accumulator +
        (current.amount * current.wholesalePrice - (current.amount * current.wholesalePrice * current.rebate) / 100),
      0,
    );
    localStorage.setItem('totalPrice', JSON.stringify(totalPriceTemp));
    setTimeout(() => {
      triggerRerender((prev) => !prev);
    }, 30);
  };

  const changeCustomerData = (id) => {
    // return;
    if (!id) {
      setCompanyName(loggedUser.data.companyAdminInfo.companyName);
      setPhone(loggedUser.data.phone[0]);
      setAddress(
        `${loggedUser.data.companyAdminInfo.address}, ${loggedUser.data.companyAdminInfo.zipCode} ${loggedUser.data.companyAdminInfo.city}`,
      );
      setEmail(loggedUser.data.email);
      setSelectedCompany(undefined);
    } else if (currentuser.data.role[0] === 'superAdmin') {
      for (const company of companies.data.items) {
        if (company._id === id) {
          setCompanyName(company.interninaziv);
          setPhone(company.tel);
          setAddress(`${company?.adresa}, ${company?.postanskibroj ? company?.postanskibroj : ''} ${company?.mesto}`);
          setEmail(company.email ? company.email : loggedUser.data.email);
          setSelectedCompany(company);
        }
      }
    } else if (currentuser.data.role[0] === 'companyAdmin') {
      for (const company of loggedUser.data.companies) {
        if (company._id === id) {
          setCompanyName(company.interninaziv);
          setPhone(company.tel);
          setAddress(`${company?.adresa}, ${company?.postanskibroj ? company?.postanskibroj : ''} ${company?.mesto}`);
          setEmail(company.email ? company.email : loggedUser.data.email);
          setSelectedCompany(company);
        }
      }
    } else {
      for (const company of loggedUser.data.companies) {
        if (company._id === id) {
          setCompanyName(company.interninaziv);
          setPhone(company.tel);
          setAddress(`${company?.adresa}, ${company?.postanskibroj ? company?.postanskibroj : ''} ${company?.mesto}`);
          setEmail(loggedUser.data.email);
          setSelectedCompany(company);
        }
      }
    }
  };
  const createOffer = async ({ type }) => {
    // type je ili ponuda ili profaktura
    if (!selectedCompany && (loggedUser.data.role[0] === 'companyAdmin' || loggedUser.data.role[0] === 'superAdmin')) {
      setDisabled(false);
      notification.error({
        message: 'Molimo Vas da odaberete ogranak.',
        placement: 'bottomRight',
      });
      return;
    }
    let offers = [];
    let company = {};
    try {
      if (currentuser.data.role[0] === 'companyAdmin' || currentuser.data.role[0] === 'superAdmin') {
        if (selectedCompany) {
          company = { selectedCompany };
          company.companyName = companyName;
          company.address = address;
          company.zipCode = selectedCompany?.postanskibroj ? selectedCompany.postanskibroj : '';
          company.city = selectedCompany?.mesto ? selectedCompany.mesto : '';
        }
      } else if (currentuser.data.role[0] === 'company') {
        company = loggedUser.data.companies[0];
        company.companyName = loggedUser?.data?.companies[0]?.interninaziv
          ? loggedUser.data.companies[0].interninaziv
          : '';
        company.address = loggedUser?.data?.companies[0]?.adresa ? loggedUser.data.companies[0].adresa : '';
        company.zipCode = loggedUser?.data?.companies[0]?.postanskibroj
          ? loggedUser.data.companies[0].postanskibroj
          : '';
        company.city = loggedUser?.data?.companies[0]?.mesto ? loggedUser.data.companies[0].mesto : '';
      }
      let orderedProducts = JSON.parse(localStorage.getItem('orderedProducts'));

      for (const orderedProduct of orderedProducts) {
        offers.push({
          name: orderedProduct.title,
          quantity: orderedProduct.amount,
          category: orderedProduct.category,
          tarifa: orderedProduct.tarifa,
          price: orderedProduct.wholesalePrice,
          code: orderedProduct.code,
          note: orderedProduct.note,
          rebate: orderedProduct.rebate,
        });
      }
      if (type === 'ponuda') {
        //ako je ponuda
        let data = {
          offer: offers,
          user: loggedUser.data,
          company: company,
          totalPrice: parseFloat(localStorage.getItem('totalPrice')),
          note: comment,
          showEuro: value,
        };

        await Axios.post(`${SERVER_URL}/offers`, data, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        notification.success({
          message: 'Kreirana je ponuda.',
          placement: 'bottomRight',
        });
        localStorage.removeItem('orderedProducts');
        localStorage.removeItem('orderedProductComment');
        localStorage.removeItem('amounts');
        localStorage.removeItem('totalPrice');
        setSelectedCompany();
        setCompanyName('');
        setAddress('');
        setPhone('');
        setEmail('');
        setAmount([]);
        setComment('');
        setTimeout(() => {
          // window.location.reload();
          history.push('/company/data');
        }, 1000);
      } else {
        // ako je profaktura BOJAN
        let data = {
          proFormInvoice: offers, // rekao si da ovo treba da se promeni na profaktura
          user: loggedUser.data,
          company: company,
          totalPrice: parseFloat(localStorage.getItem('totalPrice')),
          note: comment,
          showEuro: value,
        };

        await Axios.post(`${SERVER_URL}/proFormInvoices`, data, {
          // i promenis rutu vrv
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        notification.success({
          message: 'Kreirana je profaktura.',
          placement: 'bottomRight',
        });
        localStorage.removeItem('orderedProducts');
        localStorage.removeItem('orderedProductComment');
        localStorage.removeItem('amounts');
        localStorage.removeItem('totalPrice');
        setSelectedCompany();
        setCompanyName('');
        setAddress('');
        setPhone('');
        setEmail('');
        setComment('');
        setAmount([]);
        setTimeout(() => {
          // window.location.reload();
          history.push('/company/data');
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Došlo je do greške. Molimo Vas pokušajte opet kasnije.',
        placement: 'bottomRight',
      });
    }
    setDisabled(false);
  };

  const handleFilter = (inputValue, option) => {
    // Convert both the input value and the option's label to lowercase for case-insensitive comparison
    const inputValueLowerCase = inputValue.toLowerCase();
    const optionLabelLowerCase = option.props.children.toLowerCase();

    for (let i = 0; i < companies.data.items.length; i++) {
      if (companies.data.items[i]._id === option.value) {
        if (
          optionLabelLowerCase.includes(inputValueLowerCase) ||
          companies.data.items[i]?.pib.includes(inputValueLowerCase) ||
          companies.data.items[i]?.matbroj.includes(inputValueLowerCase)
        ) {
          return optionLabelLowerCase;
        }
      }
    }
  };

  const handleSelectClick = () => {
    setIsClicked(true);
  };

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 &&
          !categories.isLoading &&
          categories.data &&
          categories.data.length > 0 && (
            <Table
              data={tableData}
              categories={categories.data}
              deleteHandler={deleteDataHandler}
              columnKeys={newColumnKeys}
              title='Data'
              editPath='/company/edit-data/'
              increaseAmount={increaseAmount}
              decreaseAmount={decreaseAmount}
              changeAmount={changeAmount}
              amount={amount}
              addInCart={addInCart}
            />
          )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
      {orderedProducts.length !== 0 &&
        orders.data &&
        orders.data.items &&
        loggedUser.data &&
        loggedUser.data.length !== 0 && (
          <>
            {' '}
            <div className='subtitle' style={{ marginBottom: '20px', marginLeft: '5px', width: '100%' }}>
              ODABRANI ARTIKLI
            </div>
            <Row style={{ marginBottom: '25px' }}>
              <Col span={16}>
                <div style={{ marginTop: '20px' }}>
                  <OrderedProductsTable
                    data={orderedProducts}
                    deleteFromOrderHandler={deleteFromOrderHandler}
                    columnKeys={orderTableColumnKeys}
                    title='OrderedProducts'
                    increaseAmount={increaseAmountSecond}
                    decreaseAmount={decreaseAmountSecond}
                    amount={amount}
                    changeOrderedPrice={changeOrderedPrice}
                    changeOrderedRebate={changeOrderedRebate}
                    changePDV={changePDV}
                    changeComment={changeComment}
                  />
                </div>
              </Col>
              <Col className='columnCompany' span={7}>
                <div className='headerzaisporuku'>
                  <span>Detalji</span>
                </div>
                <span>
                  {currentuser.data.role.includes('companyAdmin') && (
                    <Select
                      allowClear={true}
                      onFocus={handleSelectClick}
                      placeholder={isClicked ? undefined : 'Izaberite komitenta'}
                      onChange={(value) => changeCustomerData(value)}
                      value={selectedCompany && selectedCompany._id}
                    >
                      {loggedUser.data &&
                        loggedUser.data.length !== 0 &&
                        loggedUser.data.companies.map((item) => (
                          <Select.Option key={item._id} value={item._id}>
                            {item.interninaziv}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </span>
                <span>
                  {currentuser.data.role.includes('superAdmin') && (
                    <>
                      <Select
                        allowClear={true}
                        showSearch
                        // optionFilterProp='children'
                        filterOption={handleFilter}
                        onFocus={handleSelectClick}
                        placeholder={isClicked ? undefined : 'Izaberite komitenta'}
                        onChange={(value) => changeCustomerData(value)}
                        value={selectedCompany && selectedCompany._id}
                      >
                        {companies &&
                          companies.data &&
                          companies.data.length !== 0 &&
                          companies.data.items.map((item) => (
                            <Select.Option key={item._id} value={item._id}>
                              {item.interninaziv}
                            </Select.Option>
                          ))}
                      </Select>
                      <p style={{ marginTop: '10px' }}>Izaberi valutu:</p>
                      <Radio.Group onChange={onChange} value={value}>
                        {/* <Radio.Group > */}
                        <Space style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                          <Radio value={false} className='ant_radio_button'>
                            RSD
                          </Radio>
                          <Radio value={true} className='ant_radio_button'>
                            €
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </>
                  )}
                </span>
                {!currentuser.data.role.includes('company') && <Divider type='horizontal' />}
                <span>Kupac:</span>
                <span>
                  {currentuser.data.role[0] === 'company' ? loggedUser.data.companies[0]?.interninaziv : companyName}
                </span>
                <Divider type='horizontal' />
                <span>
                  Adresa:{' '}
                  {currentuser.data.role[0] === 'company'
                    ? `${loggedUser.data.companies[0]?.adresa}, ${
                        loggedUser.data.companies[0]?.postanskibroj ? loggedUser.data.companies[0]?.postanskibroj : ''
                      } ${loggedUser.data.companies[0]?.mesto}`
                    : address}
                </span>
                <Divider type='horizontal' />
                <span>
                  Tel:{' '}
                  {currentuser.data.role[0] === 'company' ? loggedUser.data.phone && loggedUser.data.phone[0] : phone}
                </span>
                <Divider type='horizontal' />
                {/* <br /> */}
                <span>Email: {currentuser.data.role[0] === 'company' ? loggedUser.data.email : email}</span>
                <Divider type='horizontal' />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <span style={{ width: 'auto', marginBottom: '10px' }}>Komentar:</span>
                  <div style={{ padding: '0 8px', width: '100%' }}>
                    <TextArea
                      bordered={true}
                      placeholder='Komentar'
                      rows={1}
                      autoSize
                      onChange={(e) => {
                        setTimeout(() => {
                          setComment(e?.currentTarget.value);
                          localStorage.setItem('orderedProductComment', JSON.stringify(e?.currentTarget.value));
                        }, 150);
                      }}
                      defaultValue={comment && comment}
                    />
                  </div>
                </div>
                <Divider type='horizontal' />
                <Row>
                  <Col span={16}>UKUPNO ZA PLAĆANJE BEZ PDV</Col>
                  <Col span={8}>{total ? total.toFixed(2) : ''} €</Col>
                </Row>
                {/* <Row>
              <Col span={16}>PDV</Col>
              <Col span={8}>{parseFloat(total * 0.2).toFixed(2)} RSD</Col>
            </Row>
            <Divider type='horizontal' />
            <Row>
              <Col span={16}>ZA PLAĆANJE</Col>
              <Col span={8}>{(total + total * 0.2).toFixed(2)} RSD</Col>
            </Row> */}
                <Divider type='horizontal' />
                <div
                  className='button-div'
                  style={{ display: 'flex', justifyContent: 'space-around', marginTop: '5px', marginBottom: '5px' }}
                >
                  <button
                    className='b2b-primary'
                    disabled={disabled}
                    onClick={() => {
                      setDisabled(true);
                      createOffer({ type: 'ponuda' });
                    }}
                  >
                    {currentuser.data.role[0] === 'company' ? 'Zatraži ponudu' : 'Kreiraj ponudu'}
                  </button>
                  {!loggedUser?.data?.role?.includes('company') && (
                    <button
                      className='b2b-primary'
                      disabled={disabled}
                      onClick={() => {
                        setDisabled(true);
                        createOffer({ type: 'profaktura' });
                      }}
                    >
                      Kreiraj profakturu
                    </button>
                  )}
                </div>
                <br />
              </Col>
            </Row>
          </>
        )}
    </div>
  );
};

export default Data;
