import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select, Typography, Card, Table } from 'antd';
import DataTable from '../tables/DataTable';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const InvoiceForm = ({ data, onSubmit, createInvoice }) => {
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState(data.stavke);
  const [total, setTotal] = useState(data.totalPrice ? data.totalPrice : 'Nema podataka');
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const orderStatus = [
    {
      value: '',
      label: 'Plaćen'
    },
  ];

  useEffect(() => {
    const newDataArray = data.stavke.map((item) => {
      item.totalPrice = item.vpcena * item.kolicina;
      return item;
    });
    setTotal(newDataArray.reduce((a, b) => a + b.totalPrice, 0));
  }, [products]);

  let filteredProducts = [];

  if (allProducts && products) {
    filteredProducts = allProducts.filter(item =>
      products.every(cartProduct => item._id !== cartProduct._id)
    );
  }

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const call = await axios.get(`${SERVER_URL}/data-admin`);
      setAllProducts(call.data.items);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = async () => {
    // onSubmit({
    //   ...data,
    //   order: products,
    //   totalPrice: total.toFixed(2),
    //   totalPriceWithTax: (+total + +total * .2).toFixed(2),
    //   status: form.getFieldValue('status')
    // });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 700);
  };

  const columnKeys = [
    {
      key: 'sifartusl',
      title: 'Šifra artikla',
      dataIndex: 'sifartusl',
    },
    {
      key: 'nazivartusl',
      title: 'Naziv artikla',
      dataIndex: 'nazivartusl',
    },
    {
      title: 'VP cena',
      render: (item, index) => (
        `${(item.vpcena).toFixed(2)} ${data.Valuta}`
      )
    },
    {
      key: 'kolicina',
      title: 'Količina',
      dataIndex: 'kolicina',
    },
    {
      title: 'Ukupno',
      render: (item, index) => (
        `${(item.vpcena * item.kolicina).toFixed(2)} ${data.Valuta}`
      )
    }
  ];

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={() => onFinish()}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji računa' bordered={false}>
                  <p>
                    <Text strong>Broj računa: </Text>
                    {data.BrojDOk ? data.BrojDOk : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {data.kupac[0].zvanicninaziv}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {data.kupac[0].mesto}
                  </p>
                  <p>
                    <Text strong>Adresa: </Text>
                    {data.kupac[0].adresa}
                  </p>
                  <p>
                    <Text strong>Telefon: </Text>
                    {data.kupac[0].tel}
                  </p>
                  <p>
                    <Text strong>Poštanski kod: </Text>
                    {data.kupac[0].postanskibroj}
                  </p>
                  <p>
                    <Text strong>Ukupna cena: </Text>
                    {`${(+total).toFixed(2)} ${data.Valuta}`}
                  </p>
                  {/* <p>
                    <Text strong>Ukupna cena sa porezom: </Text>
                    {(+total + +total * .2).toFixed(2)}
                  </p> */}
                </Card>
                <br />
                <Form.Item label='Status računa' name='statusdok'>
                  <Select disabled>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div>
                {products && products.length > 0 &&
                  <Table
                    dataSource={products}
                    columns={columnKeys}
                    pagination={false}
                    rowKey='_id'
                  />
                }
              </div>
            </Col>
            {/* <Col style={{ marginLeft: 'auto' }}>
              <Button className='b2b-primary'
                htmltype='submit' style={{ margin: '0.5rem' }}>
                Ažuriraj
              </Button>
              <Button className='b2b-primary'
                disabled={data.status !== 'On hold'}
                onClick={() => updateOrderAndcreateInvoice()}
                style={{ margin: '0.5rem' }}>
                Potvrdi ponudu
              </Button>
            </Col> */}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default InvoiceForm;
