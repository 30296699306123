import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import InvoiceForm from '../../components/forms/InvoiceForm';

const ViewInvoice = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [invoice, setInvoice] = useState({});
  const { invoiceId } = props.match.params;

  const fetchInvoice = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/invoices/${invoiceId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setInvoice(res.data[0]);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (invoiceId) {
      fetchInvoice();
    }
  }, [invoiceId]);

  const isDataFetched = invoice && invoice.ID && currentuser.language;
  // if (isDataFetched) {
  //   order.data.user.userName = order.data.user.firstName + ' ' + order.data.user.lastName;
  // }

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/invoices'>
          <button className='b2b-primary'>Svi računi</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {invoiceId && isDataFetched && (
          <InvoiceForm
            data={invoice}
            language={currentuser.language}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewInvoice;
