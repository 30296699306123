import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import ViewBranches from '../pages/users/ViewBranches';
import AddCompanyBranch from '../pages/users/AddCompanyBranch';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import ViewOrder from '../pages/orders/ViewOrder';
import Offers from '../pages/offers/Offers';
import EditOffer from '../pages/offers/EditOffer';
import ViewOffer from '../pages/offers/ViewOffer';
import ViewInvoice from '../pages/invoices/ViewInvoice';
import ProFormInvoice from '../pages/proFormInvoices/ProFormInvoices';
import EditProFormInvoice from '../pages/proFormInvoices/EditProFormInvoice';
import ViewProFormInvoice from '../pages/proFormInvoices/ViewproFormInvoice';

const routes = [
  {
    label: 'Korisnici',
    showInMenu: [],
    children: [
      {
        label: 'Svi korisnici',
        path: '/company/users',
        component: Users,
        allowed: ['superAdmin', 'companyAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/company/new-user',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Izmena korisnika',
        path: '/company/edit-user/:userId',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/company/view-user/:id',
        component: ViewUser,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
      {
        label: 'Svi ogranci',
        path: '/company/branches',
        component: ViewBranches,
        allowed: ['companyAdmin'],
        showInMenu: false,
      },
      {
        label: 'Novi ogranak',
        path: '/company/new-branch',
        component: AddCompanyBranch,
        allowed: ['companyAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni ogranak',
        path: '/company/edit-branch/:id',
        component: AddCompanyBranch,
        allowed: ['companyAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Artikli',
    showInMenu: ['superAdmin', 'companyAdmin', 'company'],
    children: [
      {
        label: 'Svi artikli',
        path: '/company/data',
        component: Data,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
      {
        label: 'Novi artikal',
        path: '/company/new-data',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmena artikla',
        path: '/company/edit-data/:dataId',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/company/data-categories',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Dodavanje i izmena kategorije',
        path: '/company/data-categories/:categoryId',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/company/data-attributes',
        component: Attributes,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Ponude i Profakture',
    showInMenu: ['superAdmin', 'companyAdmin', 'company'],
    children: [
      {
        label: 'Sve ponude',
        path: '/company/offers',
        component: Offers,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
      {
        label: 'Izmena ponude',
        path: '/company/edit-offer/:offerId',
        component: EditOffer,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
      {
        label: 'Pregled ponude',
        path: '/company/view-offer/:offerId',
        component: EditOffer,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
      {
        label: 'Sve profakture',
        path: '/company/proform-invoices',
        component: ProFormInvoice,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
      {
        label: 'Izmena profakture',
        path: '/company/edit-proform-invoices/:proFormId',
        component: EditProFormInvoice,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
      {
        label: 'Pregled profakture',
        path: '/company/view-proform-invoices/:proFormId',
        component: EditProFormInvoice,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Narudžbe',
    showInMenu: ['superAdmin', 'companyAdmin', 'company'],
    children: [
      {
        label: 'Sve narudžbe',
        path: '/company/orders',
        component: Orders,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
      {
        label: 'Izmena narudžbe',
        path: '/company/edit-order/:orderId',
        component: EditOrder,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
      {
        label: 'Pregled narudžbe',
        path: '/company/view-order/:orderId',
        component: ViewOrder,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Računi',
    showInMenu: ['superAdmin', 'companyAdmin', 'company'],
    children: [
      {
        label: 'Svi računi',
        path: '/company/invoices',
        component: Invoices,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: true,
      },
      {
        label: 'Pregled računa',
        path: '/company/view-invoice/:invoiceId',
        component: ViewInvoice,
        allowed: ['superAdmin', 'companyAdmin', 'company'],
        showInMenu: false,
      },
    ],
  },
];

export default routes;
