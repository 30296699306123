import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ProFormInvoicesTable';
import { SERVER_URL } from '../../config';

const ProFormInvoices = () => {
  const currentuser = useContext(UserContext);
  const [proFormInvoices, fetchProFormInvoices] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    let link;
    const userId = currentuser.data.id;
    link = `${SERVER_URL}/proFormInvoices-by-user/${userId}`;
    fetchProFormInvoices(link, []);
  }, [currentuser.data.id, currentuser.data.role, currentuser.data.userType, fetchProFormInvoices]);

  let tableData = [];
  if (proFormInvoices.data && proFormInvoices.data.items && proFormInvoices.data.items.length > 0) {
    tableData = proFormInvoices.data.items.map((item) => {
      if (item.user && item.user.email) {
        item.buyer = `${item?.user?.firstName} ${item?.user?.lastName}`;
        item.email = item?.user?.email;
        item.city = item?.user?.companies[0]?.city;
        item.price = (+item?.totalPriceWithTax).toFixed(2);
        item.createdAt = new Date(item?.createdAt).toUTCString().substring(5, 22);
        item.updatedAt = new Date(item?.updatedAt).toUTCString().substring(5, 22);
        return item;
      } else if (item.guestUserData) {
        item.buyer = `${item?.user?.firstName} ${item?.user?.lastName}`;
        item.email = item?.guestUserData?.email;
        item.city = item?.user?.companies[0]?.mesto;
        item.price = (+item?.totalPriceWithTax).toFixed(2);
        item.createdAt = new Date(item?.createdAt).toUTCString().substring(5, 22);
        item.updatedAt = new Date(item?.updatedAt).toUTCString().substring(5, 22);
        return item;
      } else {
        return item;
      }
    });
  }

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        {proFormInvoices.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!proFormInvoices.isLoading && proFormInvoices.data && proFormInvoices.data.items && proFormInvoices.data.items.length > 0 && (
          <Table
            data={tableData}
            title='ProFormInvoices'
            viewPath='/company/view-proform-invoices/'
          />
        )}
        {!proFormInvoices.isLoading && proFormInvoices.data && proFormInvoices.data.items && proFormInvoices.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default ProFormInvoices;
