import React from 'react';
import { Button, Form, Row, Col, Typography, Card, Table, Input } from 'antd';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const onFinish = async (values) => {
    onSubmit(values);
  };

  const columns = [
    {
      key: '1',
      title: 'product',
      dataIndex: 'name',
    },
    {
      key: '2',
      title: 'category',
      dataIndex: 'category',
    },
    {
      key: '3',
      title: 'discount',
      dataIndex: 'discount',
    },
    {
      key: '4',
      title: 'price',
      dataIndex: 'price',
    },
    {
      key: '5',
      title: 'Količina',
      dataIndex: 'quantity',
      width: '10%',
    },
    {
      key: '6',
      title: 'Medjuzbir',
      dataIndex: 'subtotal',
    },
  ];
  let fullName = '';
  if (data.user) {
    fullName += data.user.firstName ? data.user.firstName + ' ' : '';
    fullName += data.user.lastName ? data.user.lastName : '';
  }
  let city = '';
  let address = '';
  let zip = '';
  let phone = '';
  if (data) {
    if (!data.company) {
      if (data.user.role[0] === 'companyAdmin') {
        city = data.user.companyAdminInfo.city;
        address = data.user.companyAdminInfo.address;
        zip = data.user.companyAdminInfo.zipCode;
        phone = data.user.phone[0];
      } else if (data.user.role[0] === 'company') {
        city = data?.user?.companies[0]?.mesto;
        address = data?.user?.companies[0]?.adresa;
        zip = data?.user?.companies[0].postanskibroj;
        phone = data?.user?.companies[0]?.tel;
      }
    } else if (data.company) {
      city = data?.company?.mesto;
      address = data?.company?.adresa;
      zip = data?.company?.postanskibroj;
      phone = data?.user?.phone[0];
    }
  }

  data.order.forEach((record, i) => record.rebate ? data.order[i].subtotal = (record.quantity * (record.price - record.price * +record.rebate / 100)).toFixed(2) : data.order[i].subtotal = (record.quantity * record.price).toFixed(2));

  data.order.forEach((record, i) => {
    if (record.rebate)
      data.order[i].discount = `${record.rebate}%`;
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji narudžbe' bordered={false}>
                  <p>
                    <Text strong>Broj narudžbe: </Text>
                    {data.orderNumber ? data.orderNumber : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {fullName !== '' ? fullName : `${data.guestUserData.firstName} ${data.guestUserData.lastName}`}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {city}
                  </p>
                  <p>
                    <Text strong>Adresa: </Text>
                    {address}
                  </p>
                  <p>
                    <Text strong>Telefon: </Text>
                    {phone}
                  </p>
                  <p>
                    <Text strong>Poštanski broj: </Text>
                    {zip}
                  </p>
                  <p>
                    <Text strong>Ukupna cena: </Text>
                    {data.totalPrice ? (+data.totalPrice).toFixed(2) : 'Nema podataka'}
                  </p>
                  {/* <p>
                    <Text strong>Ukupna sena sa PDV: </Text>
                    {data.totalPriceWithTax ? (+data.totalPriceWithTax).toFixed(2) : 'Nema podataka'}
                  </p> */}
                </Card>
                <br /><br />
                <Form.Item label='Status narudžbe' name='status'>
                  <Input disabled style={{ color: 'black' }}></Input>
                </Form.Item>
                {onSubmit && <div className='text-right'>
                  <Button type='primary' htmltype='submit'>
                    Update
                  </Button>
                </div>}
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Table
                  pagination={false}
                  rowKey='_id'
                  style={{ margin: '0px 0px 40px 0px' }}
                  columns={columns}
                  dataSource={data.order}
                  bordered
                />
                {createInvoice && <div className='text-right'>
                  <Button type='primary' onClick={createInvoice}>
                    Kreiraj račun
                  </Button>
                </div>}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div >
  );
};

export default OrderForm;
