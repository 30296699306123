import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table, Popconfirm, Input, Button, notification } from 'antd';
import { EyeOutlined, SearchOutlined, CopyOutlined, CopyFilled, EyeFilled } from '@ant-design/icons';
import { UserContext } from '../../App';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const ProFormInvoicesTable = ({ data, title, editPath, viewPath, revisionPath }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small' style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const copyHandler = async (id) => {
    const res = await Axios.post(`${SERVER_URL}/proform-invoice-copy/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${user.data.token}` },
    });

    notification.success({
      message: `Profaktura je kopirana.`,
      placement: 'bottomRight',
    });
    history.push('/company/');
    history.push('/company/proform-invoices');
  };

  const columns = [
    {
      key: 'BrojDOk',
      title: 'Broj profakture',
      dataIndex: 'BrojDOk',
      ...getColumnSearchProps('BrojDOk'),
    },
    {
      key: 'PrimalacNaziv',
      title: 'Kupac',
      dataIndex: 'PrimalacNaziv',
      ...getColumnSearchProps('PrimalacNaziv'),
    },
    {
      key: ['Kupac', '0', 'email'],
      title: 'Email',
      dataIndex: ['Kupac', '0', 'email'],
      ...getColumnSearchProps(['Kupac', '0', 'email']),
    },
    {
      key: ['Kupac', '0', 'tel'],
      title: 'Broj telefona',
      dataIndex: ['Kupac', '0', 'tel'],
      ...getColumnSearchProps(['Kupac', '0', 'tel']),
    },
    {
      key: 'AgentNaziv',
      title: 'Kreirao',
      render: (record) => <span>{`${record.createdBy[0].firstName} ${record.createdBy[0].lastName}`}</span>,
    },
    {
      title: 'Status',
      render: (rec) => {
        if (rec.statusdok === 'D') {
          return 'Aktivna';
        } else if (rec.statusdok === 'P') {
          return 'Prihvaćena';
        } else if (rec.statusdok === 'N') {
          return 'Neaktivna';
        }
      },
      // ...getColumnSearchProps('Status'),
    },
    {
      key: 'Datum',
      title: 'Datum kreiranja',
      render: (record) => <span>{new Date(record.Datum).toLocaleDateString('sr-Latn-RS', dateOptions)}</span>,
    },
  ];

  columns.push({
    title: 'action',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                // title={`View ${title.toLowerCase()}`}
                title={`Pregled profakture`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeFilled
                className='icon-lock'
                // title={`View ${title.toLowerCase()}`}
                title={`Pregled profakture`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <CopyOutlined
            className='icon-unlock'
            title={`Kopiraj profakturu`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => {
              copyHandler(record._id);
            }}
          />
          <CopyFilled
            className='icon-lock'
            title={`Kopiraj profakturu`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => {
              copyHandler(record._id);
            }}
          />
        </div>
      </div>
    ),
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default ProFormInvoicesTable;
