import React, { useEffect, useState, useContext } from 'react';
import { Button, Form, Row, Col, Select, Typography, Card, Table, Input, InputNumber, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import DataTable from '../tables/DataTable';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, DeleteOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import TextArea from 'antd/es/input/TextArea';
import Axios from 'axios';

const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const ProFormInvoiceForm = ({ data, onSubmit, createInvoice, allowEdit }) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [allArticles, setAllArticles] = useState([]);
  const [comment, setComment] = useState(data.Napomena);
  const [products, setProducts] = useState(data.stavke);
  const [newItemsArr, setNewItemsArr] = useState([]);
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  initialValues.kupacID = initialValues.kupac[0]?._id;
  initialValues.email = initialValues.kupac[0]?.email;
  initialValues.mesto = initialValues.kupac[0]?.mesto;
  initialValues.adresa = initialValues.kupac[0]?.adresa;
  initialValues.tel = initialValues.kupac[0]?.tel;
  initialValues.postanskibroj = initialValues.kupac[0]?.postanskibroj;
  initialValues.pib = initialValues.kupac[0]?.pib;
  initialValues.matbroj = initialValues.kupac[0]?.matbroj;

  const orderStatus = [
    {
      value: 'D',
      label: 'Aktivna',
    },
    {
      value: 'P',
      label: 'Prihvaćena',
    },
    {
      value: 'N',
      label: 'Neaktivna',
    },
  ];

  const fetchAllArticles = async () => {
    const res = await Axios.get(`${SERVER_URL}/data-admin`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    setAllArticles(res.data.items);
  };

  const handleItemDelete = async (e) => {
    try {
      const res = await Axios.delete(`${SERVER_URL}/proform-item/${e._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });

      history.push('/admin');
      history.push(`/admin/view-proform-invoice/${res.data.id}`);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const changeOrderedPrice = (event, id) => {
    let oldOrderedProducts = products.map((item) => {
      if (item.ID === id) {
        item.vpcena = event;
      }
      return item;
    });
    setProducts(oldOrderedProducts);
  };

  const changeOrderedRebate = (event, id) => {
    let oldOrderedProducts = products.map((item) => {
      if (item.ID === id) {
        item.rabatd = event;
        item.rabatk = event;
      }
      return item;
    });
    setProducts(oldOrderedProducts);
  };

  const total =
    products && products.length && products.reduce((acc, curr) => acc + curr.vpcena * curr.kolicina, 0).toFixed(2);
  const totalDiscount =
    products &&
    products.length &&
    products.reduce((acc, curr) => acc + (curr.vpcena * curr.kolicina * curr.rabatd) / 100, 0).toFixed(2);
  const totalPDV =
    products &&
    products.length &&
    products
      .reduce((acc, curr) => {
        let tarifaNum = 0;

        if (curr.tarifa === 'PDVOS') {
          tarifaNum = 0.2;
        } else if (curr.tarifa === 'PDVPS') {
          tarifaNum = 0.1;
        }

        return acc + (curr.vpcena * curr.kolicina - (curr.vpcena * curr.kolicina * curr.rabatd) / 100) * tarifaNum;
      }, 0)
      .toFixed(2);

  const totalWoPDV = +total - +totalDiscount;
  const grandTotal = +totalWoPDV + +totalPDV;

  const onFinish = async () => {
    let values = form.getFieldsValue();

    for (let i = 0; i < companies.length; i++) {
      if (companies[i]._id === values.kupacID) {
        values.komitentID = companies[i].ID;
      }
    }

    onSubmit({
      ...data,
      ...values,
      order: products,
      totalPrice: parseFloat(total).toFixed(2),
      totalPriceWithTax: (+total + +total * 0.2).toFixed(2),
      status: form.getFieldValue('status'),
      note: form.getFieldValue('note'),
    });
  };

  const handleSaveNewItems = async () => {
    try {
      let values = {
        artikal: form.getFieldValue('artikal_noviitem'),
        cena: form.getFieldValue('cena_noviitem'),
        kolicina: form.getFieldValue('kolicina_noviitem'),
        popust: form.getFieldValue('popust_noviitem'),
        komentar: form.getFieldValue('komentar_noviitem'),
      };

      const res = await Axios.post(`${SERVER_URL}/proform-item/${data._id}`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Spisak stavki je ažuriran.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with adding new items. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const changeComment = (product, comment) => {
    let objIndex = products.findIndex((obj) => obj?.ID == product?.ID);
    products[objIndex].napomena = comment;
  };

  const fetchCompanies = async () => {
    const res = await Axios.get(`${SERVER_URL}/companies`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    setCompanies(res.data.items);
  };

  useEffect(() => {
    fetchAllArticles();
    fetchCompanies();
  }, []);

  const onChangeCompany = (e) => {
    for (let i = 0; i < companies.length; i++) {
      if (companies[i]._id === e) {
        form.setFieldsValue({
          email: companies[i].email,
          mesto: companies[i].mesto,
          adresa: companies[i].adresa,
          tel: companies[i].tel,
          postanskibroj: companies[i].postanskibroj,
          pib: companies[i].pib,
          tel: companies[i].tel,
        });
      }
    }
  };

  const columnKeys = [
    {
      key: 'ID',
      title: 'R.b.',
      render: (text, record, index) => <span>{index + 1}.</span>,
    },
    {
      key: 'sifartusl',
      title: 'Šifra artikla',
      dataIndex: 'sifartusl',
    },
    {
      key: 'nazivartusl',
      title: 'Naziv artikla',
      dataIndex: 'nazivartusl',
    },
    {
      key: 'jdm',
      title: 'JM',
      dataIndex: 'jdm',
    },
    {
      key: 'kolicina',
      title: 'Količina',
      dataIndex: 'kolicina',
    },
    {
      title: 'Cena',
      render: (record, index) => (
        <div style={{ margin: '0 auto', width: '60px', outline: '0', borderBottom: '1px solid black' }}>
          <Input
            style={{ padding: '0px' }}
            bordered={false}
            placeholder=''
            onChange={(e) => changeOrderedPrice(e.currentTarget.value, record.ID)}
            defaultValue={record.vpcena}
          />
        </div>
      ),
    },
    {
      title: 'Iznos',
      render: (record, index) => (
        <div style={{ margin: '0 auto' }}>
          <span>{record.vpcena * record.kolicina}</span>
        </div>
      ),
    },
    {
      title: 'Rabat %',
      render: (text, record, index) => (
        <>
          <div style={{ margin: '0 auto', width: '60px', outline: '0', borderBottom: '1px solid black' }}>
            <Input
              style={{ padding: '0px' }}
              bordered={false}
              placeholder='%'
              onChange={(e) => changeOrderedRebate(e.currentTarget.value, record.ID)}
              defaultValue={record.rabatd}
            />
          </div>
        </>
      ),
    },
    {
      title: 'Ukupno',
      render: (item, index) =>
        `${(item.vpcena * item.kolicina - (item.vpcena * item.kolicina * item.rabatd) / 100).toFixed(2)} ${
          data.Valuta
        }`,
    },
    {
      key: 'action',
      render: (record, index) => {
        return (
          <div style={{ margin: '2px', padding: '4px' }} className='lock'>
            <DeleteOutlined
              className='icon-unlock'
              // title={`Delete ${title.toLowerCase()}`}
              title={`Brisanje stavke`}
              onClick={() => {
                handleItemDelete(record);
              }}
            />
            <DeleteFilled
              className='icon-lock'
              // title={`Delete ${title.toLowerCase()}`}
              title={`Brisanje stavke`}
              onClick={() => {
                handleItemDelete(record);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={() => onFinish()}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji profakture' bordered={false}>
                  <p>
                    <Text strong>Broj profakture: </Text>
                    {data.BrojDOk ? data.BrojDOk : 'Nema podataka'}
                  </p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>Kupac: </Text>
                    <Form.Item name='kupacID' style={{ margin: 0 }}>
                      <Select
                        style={{ maxWidth: '200px', marginLeft: '10px' }}
                        onChange={(e) => {
                          onChangeCompany(e);
                        }}
                      >
                        {companies &&
                          companies.length > 0 &&
                          companies.map((item, index) => {
                            return <Option value={item._id}>{item.zvanicninaziv}</Option>;
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>Email: </Text>
                    <Form.Item name='email' style={{ margin: 0 }}>
                      <Input className='input_like_label' disabled />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>Grad: </Text>
                    <Form.Item name='mesto' style={{ margin: 0 }}>
                      <Input className='input_like_label' disabled />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>Adresa: </Text>
                    <Form.Item name='adresa' style={{ margin: 0 }}>
                      <Input className='input_like_label' disabled />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>Telefon: </Text>
                    <Form.Item name='tel' style={{ margin: 0 }}>
                      <Input className='input_like_label' disabled />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>Poštanski kod: </Text>
                    <Form.Item name='postanskibroj' style={{ margin: 0 }}>
                      <Input className='input_like_label' disabled />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>PIB: </Text>
                    <Form.Item name='pib' style={{ margin: 0 }}>
                      <Input className='input_like_label' disabled />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text strong>MB: </Text>
                    <Form.Item name='matbroj' style={{ margin: 0 }}>
                      <Input className='input_like_label' disabled />
                    </Form.Item>
                  </div>
                  <p>
                    <Text strong>Ukupno: </Text>
                    {`${(+total).toFixed(2)} ${data.Valuta}`}
                  </p>
                  <p>
                    <Text strong>Rabat, popust: </Text>
                    {`${(+totalDiscount).toFixed(2)} ${data.Valuta}`}
                  </p>
                  <p>
                    <Text strong>Iznos bez PDV-a: </Text>
                    {`${(+totalWoPDV).toFixed(2)} ${data.Valuta}`}
                  </p>
                  <p>
                    <Text strong>PDV: </Text>
                    {`${(+totalPDV).toFixed(2)} ${data.Valuta}`}
                  </p>
                  <p>
                    <Text strong>Za uplatu: </Text>
                    {`${(+grandTotal).toFixed(2)} ${data.Valuta}`}
                  </p>
                </Card>
                <br />
                <Form.Item label='Status profakture' name='statusdok'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label='Komentar' name='Napomena'>
                  <TextArea
                    bordered={true}
                    placeholder='Komentar'
                    autoSize
                    onChange={(e) => {
                      setComment(e?.currentTarget.value);
                      // localStorage.setItem('orderedProductComment', JSON.stringify(e?.currentTarget.value));
                    }}
                    defaultValue={comment && comment}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div>
                {products && products.length > 0 && (
                  <Table
                    dataSource={products}
                    columns={columnKeys}
                    pagination={false}
                    rowKey='_id'
                    expandable={{
                      showExpandColumn: false,
                      defaultExpandAllRows: true,
                      expandedRowKeys: products.map((o) => o._id),
                      expandedRowRender: (record) => {
                        return (
                          <div style={{ borderBottom: '1px solid #d9d9d9', paddingBottom: '10px', display: 'flex' }}>
                            <Input
                              bordered={true}
                              placeholder=''
                              onChange={(e) => changeComment(record, e?.currentTarget.value)}
                              defaultValue={record.napomena}
                            />
                          </div>
                        );
                      },
                      rowExpandable: (record) => record.name !== 'Not Expandable',
                    }}
                  />
                )}
                <Button
                  className='b2b-primary'
                  style={{ margin: '0.5rem' }}
                  onClick={() => setNewItemsArr([...newItemsArr, {}])}
                  disabled={disabled}
                >
                  Novi artikal
                </Button>
                {newItemsArr.length > 0 && (
                  <Button
                    type='primary'
                    onClick={handleSaveNewItems}
                    shape='round'
                    style={{ background: 'grey', margin: '8px' }}
                  >
                    Sačuvaj artikle
                  </Button>
                )}
                {newItemsArr.length > 0 && (
                  <Card style={{ marginTop: '10px' }} title='Dodaj nove artikle u profakturu' bordered={false}>
                    {newItemsArr &&
                      newItemsArr.length > 0 &&
                      newItemsArr.map((item, index) => {
                        return (
                          <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex' }}>
                              <label>Artikal:</label>
                              <Form.Item
                                name={['artikal_noviitem', index]}
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <Select style={{ width: '350px', marginLeft: '10px' }}>
                                  {allArticles &&
                                    allArticles.length > 0 &&
                                    allArticles.map((item, index) => {
                                      return <Option value={item._id}>{item.naziv}</Option>;
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div style={{ display: 'flex' }}>
                              <label>Cena:</label>
                              <Form.Item
                                name={['cena_noviitem', index]}
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <InputNumber
                                  min='0'
                                  style={{ width: '72px', marginLeft: '15px', marginRight: '10px' }}
                                ></InputNumber>
                              </Form.Item>
                              <label>Količina:</label>
                              <Form.Item
                                name={['kolicina_noviitem', index]}
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <InputNumber
                                  min='0'
                                  style={{ width: '72px', marginLeft: '10px', marginRight: '10px' }}
                                ></InputNumber>
                              </Form.Item>
                              <label>Rabat:</label>
                              <Form.Item
                                name={['popust_noviitem', index]}
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <InputNumber min='0' style={{ width: '72px', marginLeft: '10px' }}></InputNumber>
                              </Form.Item>
                            </div>

                            <div style={{ display: 'flex' }}>
                              <label>Komentar:</label>
                              <Form.Item name={['komentar_noviitem', index]} style={{ display: 'flex' }}>
                                <Input style={{ width: '330px', marginLeft: '10px' }}></Input>
                              </Form.Item>
                            </div>
                            <div style={{ borderBottom: '1px solid gray', marginBottom: '20px' }}></div>
                          </div>
                        );
                      })}
                  </Card>
                )}
              </div>
            </Col>
            {allowEdit && (
              <Col style={{ marginLeft: 'auto' }}>
                <Button className='b2b-primary' onClick={() => onFinish()} style={{ margin: '0.5rem' }}>
                  Ažuriraj
                </Button>
                {/* <Button className='b2b-primary'
                  disabled={data.status !== 'On hold'}
                  onClick={() => updateOrderAndcreateInvoice()}
                  style={{ margin: '0.5rem' }}>
                  Potvrdi profakturu
                </Button> */}
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ProFormInvoiceForm;
