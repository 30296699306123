import React, { useContext, useState } from 'react';
import { Table, Input, Button, InputNumber } from 'antd';
import { EyeOutlined, MinusOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DataTable = ({
  data,
  categories,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  increaseAmount,
  changeAmount,
  decreaseAmount,
  amount,
  addInCart,
}) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  let searchInput;

  const modifiedCategories = categories.map((category) => {
    const newCategory = {
      value: category.naziv,
      text: category.naziv,
    };
    return newCategory;
  });

  const modifiedLager = [
    { text: 'Na upit', value: 'Na upit' },
    { text: 'Na stanju', value: 'Na stanju' },
  ];

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary' onClick={() => handleReset(clearFilters, confirm)} style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      let filterRecord;

      if (dataIndex === 'state') {
        filterRecord = Array.isArray(dataIndex)
          ? !dataIndex.includes('category')
            ? record[dataIndex].props.children
            : record[dataIndex].props.children
          : record[dataIndex].props.children;
      } else {
        filterRecord = Array.isArray(dataIndex)
          ? !dataIndex.includes('category')
            ? record[dataIndex[0]][dataIndex[1]]
            : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
          : record[dataIndex];
      }

      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const columns = columnKeys.map((item) => ({
    key: item.originalName,
    title: item.alternativeName,
    dataIndex:
      item.originalName === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item.originalName)
        ? item.originalName === 'title'
          ? [item.originalName, user.language ? user.language.selected.code : '']
          : [item.originalName, 'name', user.language ? user.language.selected.code : '']
        : item.originalName,
    ...getColumnSearchProps(
      item.originalName === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item.originalName)
        ? item.originalName === 'title'
          ? [item.originalName, user.language ? user.language.selected.code : '']
          : [item.originalName, 'name', user.language ? user.language.selected.code : '']
        : item.originalName,
    ),
  }));

  columns.push(
    {
      title: 'Cena',
      render: (text, record, index) => (
        <span>{record?.cena[0]?.Cena ? `${record?.cena[0]?.Cena?.toFixed(2)} €` : 'Na upit'}</span>
      ),
    },
    {
      title: 'Lager',
      dataIndex: 'state',
      key: 'state',
      filters: modifiedLager,
      onFilter: (value, record) => {
        if (value === 'Na upit') {
          return record.state.props.children.includes(value);
        } else {
          return record.state.props.children[1].includes(value);
        }
      },
    },
    {
      title: 'Rezervisano',
      dataIndex: 'reserved',
      key: 'reserved',
    },
    {
      title: 'Pozajmica',
      dataIndex: 'loan',
      key: 'loan',
    },
    {
      title: 'Raspoloživo',
      render: (rec) => {
        let stanje = rec && rec.stanje && rec.stanje.length > 0 ? rec.stanje[0].stanje : 0;

        return stanje - rec.reserved - rec.loan;
      },
    },
    {
      title: 'Kategorija',
      width: '19%',
      filters: modifiedCategories,
      onFilter: (value, record) => record.kategorija[0]?.naziv.includes(value),
      render: (text, record) => <span>{record?.kategorija[0]?.naziv}</span>,
    },
    {
      title: 'quantity',
      width: '15%',
      render: (text, record, index) => (
        <div>
          <Button
            // disabled={!record?.stanje[0]?.stanje || (record?.stanje[0]?.stanje && amount[(currentPage - 1) * pageSize + index] === record?.stanje[0]?.stanje)}
            onClick={() => increaseAmount(record.sifra)}
            icon={<PlusOutlined />}
            style={{ border: '0', width: '15px', marginRight: '5px', height: '5px' }}
          />
          <InputNumber
            className='input_quantity_data'
            onChange={(e) => changeAmount(record.sifra, e)}
            value={
              amount.find((item) => item.code === record.sifra)
                ? amount.find((item) => item.code === record.sifra).quantity
                : 0
            }
          ></InputNumber>
          <Button
            disabled={
              !amount.find((item) => item.code === record.sifra)?.quantity ||
              amount.find((item) => item.code === record.code)?.quantity === 0
            }
            onClick={() => decreaseAmount(record.sifra)}
            icon={<MinusOutlined />}
            style={{ border: '0', width: '15px', marginLeft: '5px', height: '5px' }}
          />
        </div>
      ),
    },
  );

  // columns.push({
  //   title: 'action',
  //   render: (text, record, index) => (
  //     <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: '15px' }}>   {/* na desno Actions   umesto inline-flex i center */}
  //       {/* {user.data.role.includes('superAdmin') && (
  //         <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
  //           <Link to={editPath + record._id}>
  //             <EditOutlined
  //               className='icon-unlock'
  //               title={`Edit ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //             <EditFilled
  //               className='icon-lock'
  //               title={`Edit ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //           </Link>
  //         </div>
  //       )} */}

  //       {viewPath && (
  //         <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
  //           <Link to={viewPath + record._id}>
  //             <EyeOutlined
  //               className='icon-unlock'
  //               title={`View ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //             <EyeOutlined
  //               className='icon-lock'
  //               title={`View ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //           </Link>
  //         </div>
  //       )}

  //       {/* {user.data.role.includes('superAdmin') && (
  //         <div style={{ margin: '2px', padding: '4px' }} className='lock'>
  //           <Popconfirm
  //             disabled={record._id === user.data.id}
  //             placement='left'
  //             title={`This will delete ${title.toLowerCase()}`}
  //             onConfirm={() => {
  //               deleteHandler(record._id);
  //             }}
  //             okText='Ok'
  //             cancelText='Cancel'
  //           >
  //             <DeleteOutlined className='icon-unlock' title={`Delete ${title.toLowerCase()}`} />
  //             <DeleteFilled className='icon-lock' title={`Delete ${title.toLowerCase()}`} />
  //           </Popconfirm>
  //         </div>
  //       )} */}
  //       {/* {!user.data.role.includes('superAdmin') && <Button
  //         disabled={!record?.stanje[0]?.stanje}
  //         className='b2b-primary'
  //         onClick={() => addInCart((currentPage - 1) * pageSize + index)}>
  //         + Poruči
  //       </Button>} */}
  //     </div>
  //   ),
  // });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div>
      <Table
        size='small'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 15,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default DataTable;
